import { IconButton, MaterialIcon } from '@koolumbus/web/ui';
import React from 'react';

interface DefaultTablePaginationProps {
  count: number;
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage?: (value: number) => void;
}

const DefaultTablePagination: React.FC<React.PropsWithChildren<DefaultTablePaginationProps>> = ({
  count,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}) => {
  function handleChangeRowsPerPage(ev: React.ChangeEvent<HTMLSelectElement>) {
    if (typeof setRowsPerPage === 'function') {
      const res = parseInt(ev.target.value, 10);
      if (!isNaN(res)) {
        setRowsPerPage(res);
        // setPage(1);
      }
    }
  }

  const rangeStart = (page - 1) * rowsPerPage + 1;
  const rangeEnd = (page - 1) * rowsPerPage + rowsPerPage;

  return (
    <td className="p-0 h-[52px] text-right" colSpan={1000}>
      <div className="flex relative items-center text-gray-600">
        <div className="flex-1" />
        <p className="mr-4 flex-shrink-0">Rows per page:</p>

        <select
          className="mr-4 w-20 border-none focus:ring-0"
          onChange={handleChangeRowsPerPage}
          defaultValue="25"
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>

        <p className="flex-shrink-0 mr-4">{`${rangeStart} - ${
          rangeEnd > count ? count : rangeEnd
        } of ${count}`}</p>

        <div className="mr-3 space-x-2">
          <IconButton
            variant="ghost"
            icon={<MaterialIcon className="text-gray-500" icon="chevron-left" />}
            isDisabled={page === 1}
            aria-label="previous"
            onClick={() => setPage(page - 1)}
          />

          <IconButton
            variant="ghost"
            icon={<MaterialIcon className="text-gray-500" icon="chevron-right" />}
            isDisabled={page * rowsPerPage > count}
            aria-label="next"
            onClick={() => setPage(page + 1)}
          />
        </div>
      </div>
    </td>
  );
};

export default DefaultTablePagination;
