import React from 'react';

interface PageHeaderProps {
  children?: React.ReactNode;
  loading?: boolean;
  title: string | React.ReactNode;
  subtitle?: string;
}

const PageHeader = ({
  loading,
  title,
  subtitle,
  children,
}: React.PropsWithChildren<PageHeaderProps>) => {
  const hasSubtitle = subtitle && subtitle !== '';
  const subtitlePlaceholder = <div className="h-7 w-12" />;

  const topPlaceholder = (
    <div>
      <div className="skeleton w-52 h-7" />
      <div className="skeleton w-32 h-6 mt-2" />
    </div>
  );

  const titleAndSubtitle = (
    <div>
      {typeof title === 'string' ? <h3 className="text-2xl font-medium">{title}</h3> : title}
      {hasSubtitle ? (
        <h4 className="text-gray-400 font-light mt-1 ml-0.5">{subtitle}</h4>
      ) : (
        subtitlePlaceholder
      )}
    </div>
  );

  return (
    <div className="flex flex-col p-2 pb-0 sm:p-0 lg:pl-0 mt-2 lg:mt-0 mb-4">
      <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 justify-between md:items-center mb-1 md:mb-2 lg:mb-4">
        <div className="pl-2 md:pl-1">{loading ? topPlaceholder : titleAndSubtitle}</div>
        <div className="flex justify-start flex-wrap p-1 pb-0 sm:p-0 md:justify-end gap-2 lg:gap-3">
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
