import {
  CloseButton,
  Combobox,
  ComboboxOptionProps,
  InputBase,
  InputBaseProps,
  useCombobox,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useState } from 'react';
import { ProcedureOutput, trpc } from '../../../lib/trpc';

export type ZoneValue = Pick<ProcedureOutput['zones']['list']['items'][number], 'id' | 'name'>;

export interface SelectZoneProps {
  value: ZoneValue | null;
  onChange: (value: ZoneValue | null) => void;
  required?: boolean;
  placeholder?: string;
  label?: string;
  classNames?: InputBaseProps['classNames'];
}

export default function SelectZone({
  value,
  onChange,
  label,
  placeholder,
  required,
  classNames,
}: SelectZoneProps) {
  const [searchTerm, setSearchTerm] = useState(value ? value.name : '');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 200);

  const { data } = trpc.zones.list.useQuery(
    {
      searchTerms: {
        name: debouncedSearchTerm,
      },
    },
    { keepPreviousData: true },
  );

  const items: ZoneValue[] = (data?.items ?? []).map((item) => ({
    id: item.id,
    name: item.name,
  }));

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  function onOptionSubmit(_: string, option: ComboboxOptionProps) {
    const item = items.find((item) => item.id === option.value);

    if (item) {
      onChange(item);
      setSearchTerm(item.name);
    } else {
      onChange(null);
      setSearchTerm('');
    }

    combobox.closeDropdown();
  }

  const options = items.map((item) => (
    <Combobox.Option value={item.id} key={item.id}>
      <div className="flex items-center justify-between">
        <span>{item.name}</span>
      </div>
    </Combobox.Option>
  ));

  return (
    <Combobox store={combobox} withinPortal={false} onOptionSubmit={onOptionSubmit}>
      <Combobox.Target>
        <InputBase
          value={searchTerm}
          onChange={(event) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearchTerm(event.currentTarget.value);
          }}
          required={required}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            setSearchTerm(value?.name ?? '');
          }}
          pointer
          rightSection={
            value !== null ? (
              <CloseButton
                size="sm"
                onMouseDown={(event) => event.preventDefault()}
                onClick={() => {
                  onChange(null);
                  setSearchTerm('');
                }}
                aria-label="Clear value"
              />
            ) : (
              <Combobox.Chevron />
            )
          }
          onClick={() => combobox.openDropdown()}
          rightSectionPointerEvents={value === null ? 'none' : 'all'}
          placeholder={placeholder}
          label={label}
          classNames={classNames}
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {options.length > 0 ? options : <Combobox.Empty>Nothing found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
