import clsx from 'clsx';

interface TableRowsLoadingPlaceholderProps {
  dense?: boolean;
  small?: boolean;
}

const TableRowsLoadingPlaceholder: React.FC<
  React.PropsWithChildren<TableRowsLoadingPlaceholderProps>
> = ({ dense = false, small = false }) => {
  const cellClass = 'px-6 py-4';

  return (
    <>
      {Array.from({ length: 10 })
        .map((_, i) => i)
        .map((i) => (
          <tr className="mt-4 w-full" key={i}>
            <td className={clsx(cellClass, 'w-[20%]', dense ? 'py-[19px]' : 'py-[25px]')}>
              <div key={i}>
                <div
                  className={clsx('skeleton h-[22px]', i % 2 === 0 ? 'w-[70%]' : 'w-[60%]')}
                  key={i}
                />
              </div>
            </td>

            {!small ? (
              <td className={clsx(cellClass, 'w-[10%]', dense ? 'py-[19px]' : 'py-[25px]')}>
                <div key={i}>
                  <div
                    className={clsx('skeleton h-[22px]', i % 2 === 0 ? 'w-[80%]' : 'w-[65%]')}
                    key={i}
                  />
                </div>
              </td>
            ) : null}

            <td className={clsx(cellClass, 'w-[40%]', dense ? 'py-[19px]' : 'py-[25px]')}>
              <div key={i}>
                <div
                  className={clsx('skeleton h-[22px]', i % 2 === 0 ? 'w-[70%]' : 'w-[65%]')}
                  key={i}
                />
              </div>
            </td>

            <td className={clsx(cellClass, 'w-[10%]', dense ? 'py-[19px]' : 'py-[25px]')}>
              <div key={i}>
                <div className={clsx('skeleton h-[22px] w-[98%]')} key={i} />
              </div>
            </td>

            <td className={clsx(cellClass, 'w-[20%]', dense ? 'py-[19px]' : 'py-[25px]')}>
              <div key={i}>
                <div className={clsx('skeleton h-[22px] w-[98%]')} key={i} />
              </div>
            </td>
          </tr>
        ))}
    </>
  );
};

export default TableRowsLoadingPlaceholder;
