import { getUTCDate } from '@koolumbus/shared/utils';
import {
  PaginationParams,
  datesParam,
  jsonParam,
  paginationParams,
  useLocale,
  useSearchParams,
} from '@koolumbus/web/utils';
import { Badge, Button, Tooltip } from '@mantine/core';
import { DatePickerInput, DatesRangeValue } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { trpc } from '../../lib/trpc';
import { DashboardItem } from '../../server/others/dashboard';
import DefaultTablePagination from '../common/DefaultTablePagination';
import RolesSwitch from '../common/RolesSwitch';
import SelectZone, { ZoneValue } from '../common/fields/SelectZone';
import TableNoResultsPlaceholder from '../common/tables/TableNoResultsPlaceholder';
import TableRowsLoadingPlaceholder from '../common/tables/TableRowsLoadingPlaceholder';
import PageHeader from '../layout/content/header/PageHeader';

export default function Dashboard() {
  const locale = useLocale();
  const router = useRouter();

  const {
    pagination: { page, rowsPerPage, setPage, setRowsPerPage },
    params,
    setParams,
  } = useDashboardParams();

  const dateFrom = dayjs
    .utc(getUTCDate(params.dates ? params.dates[0] : new Date()))
    .startOf('d')
    .toDate();

  const dateTo = dayjs
    .utc(getUTCDate(params.dates ? params.dates[1] : new Date()))
    .endOf('d')
    .toDate();

  const { data, isLoading } = trpc.dashboard.useQuery(
    {
      dateFrom,
      dateTo,
      zonesIds: params.zone ? [params.zone.id] : undefined,
      pagination: {
        page: params.page || 1,
        results: 50,
      },
    },
    { keepPreviousData: true },
  );

  const count = data?.count ?? 0;

  const [datesValue, setDatesValue] = useState<DatesRangeValue>(
    params.dates ? [params.dates[0], params.dates[1]] : [null, null],
  );

  function onChangeDates(value: DatesRangeValue) {
    setDatesValue(value);

    if (value[0] && value[1]) {
      setParams({
        dates: [value[0], value[1]],
      });
    }

    if (!value[0] && !value[1]) {
      setParams({
        dates: undefined,
      });
    }
  }

  return (
    <>
      <PageHeader title="Dashboard" subtitle="Le tue prenotazioni ed interventi">
        <RolesSwitch admin>
          <SelectZone
            value={params.zone ?? null}
            onChange={(zone) => setParams({ zone: zone ?? undefined })}
            placeholder="Tutte le zone"
            classNames={{
              input: 'min-w-[12rem] !min-h-[2.25rem] text-sm',
            }}
          />
        </RolesSwitch>

        <DatePickerInput
          placeholder="Today"
          type="range"
          value={datesValue}
          onChange={onChangeDates}
          classNames={{
            input: 'min-w-[12rem] !min-h-[2.25rem] text-sm',
          }}
          leftSection={<IconCalendar size="1rem" />}
          popoverProps={{
            position: 'bottom-end',
          }}
          clearable
          allowSingleDateInRange
        />
      </PageHeader>

      <div className="card p-0 relative w-full overflow-x-auto">
        <table className="card p-0 relative w-full overflow-x-auto">
          {isLoading && <TableRowsLoadingPlaceholder />}

          {data && data.items.length === 0 && (
            <TableNoResultsPlaceholder text="Nessun intervento o prenotazione trovati in queste date" />
          )}

          {data && data.items.length > 0 && (
            <>
              <tbody className="bg-white divide-y divide-gray-200">
                {data?.items.map((item) => (
                  <tr key={`${item.id}-${item.type}`}>
                    <td className="table-cell">
                      <Tooltip label={item.type === 'task' ? 'Intervento' : 'Prenotazione'}>
                        <Badge color={item.type === 'task' ? undefined : 'blue'}>
                          {formatItemType(item.type === 'task' ? item.taskType! : item.type)}
                        </Badge>
                      </Tooltip>
                    </td>

                    <td className="table-cell">{item.property?.name || 'Unknown'}</td>

                    <td className="table-cell">{getItemDateTime(item, locale)}</td>

                    <td className="table-cell">{item.assignee?.fullName || 'Unknown'}</td>

                    <td className="table-cell text-right">
                      <Button
                        variant="subtle"
                        onClick={() =>
                          router.push(
                            item.type === 'task' ? `/tasks/${item.id}` : `/bookings/${item.id}`,
                          )
                        }
                      >
                        Dettagli
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>

              {data.count > 25 && (
                <tfoot>
                  <tr className="border-white">
                    <DefaultTablePagination
                      count={count}
                      page={page}
                      setPage={setPage}
                      rowsPerPage={rowsPerPage}
                      setRowsPerPage={setRowsPerPage}
                    />
                  </tr>
                </tfoot>
              )}
            </>
          )}
        </table>
      </div>
    </>
  );
}

function getItemDateTime(item: DashboardItem, locale: string) {
  let date = dayjs(item.date);

  if (typeof item.time === 'number') {
    date = date.startOf('day').minute(item.time);
  }

  return date.locale(locale).format('D MMM, YY - HH:mm');
}

type DashboardParams = PaginationParams & {
  dates: [Date, Date];
  zone: ZoneValue;
};

function useDashboardParams() {
  const { params, setParams: _setParams } = useSearchParams<DashboardParams>({
    ...paginationParams,
    dates: datesParam,
    zone: jsonParam<ZoneValue>(),
  });

  const setParams: typeof _setParams = (value) => {
    _setParams({ ...value, page: 1 });
  };

  const setRowsPerPage = (value: number) => {
    return _setParams({
      rows: value,
      page: undefined,
    });
  };

  return {
    pagination: {
      page: params.page!,
      rowsPerPage: params.rows!,
      setPage: (page: number) => _setParams({ page }),
      setRowsPerPage,
    },
    params,
    setParams,
  };
}

function formatItemType(type: string) {
  return type.replace(/_/g, ' ').toUpperCase();
}
