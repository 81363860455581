interface TableNoResultsPlaceholderProps {
  text?: string;
}

const TableNoResultsPlaceholder: React.FC<
  React.PropsWithChildren<TableNoResultsPlaceholderProps>
> = ({ text }) => {
  return (
    <tr>
      <td className="table-cell">
        <p className="text-gray-500">{text ?? 'Nessun risultato trovato'}</p>
      </td>
    </tr>
  );
};

export default TableNoResultsPlaceholder;
